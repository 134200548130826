@import "./../../../../../assets/themes/mi-theme/variables";
$defaultThemeColor: #FFF;
$secondaryThemeColor: #3D4246;
$sidebarBgColor: #FFF;
$sidebarMenuTextColor: #000;
$sideBarHoverBgColor: #0C1224;
$sidebarMenuHoverTextColor: #FFF;
$buttonDefaultBgColor: $defaultThemeColor;
$topBarBgColor: #FFF;
$topBarItemHoverBgColor: #F1F1F1;
$sidebarIconColor: #B0BEC5;
$sidebarActiveColor: #F7941D;

:host::ng-deep {
  .sidebar-dropdown {
    .mi-lg {
      padding: 8px 8px;
  
      &.calendar_event {
          padding: 14px;
          margin-left: 5px;
          background-color: #c8c8c8;
      }
  }
  }


  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 133px;
  }
}

.option-icon {
  width: 24px;
  height: 24px;
  margin-left: 6px;
  border-radius: 6px;
}

.sidebar-event{
  margin-left: 8px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.company-logo {
  background-color: $defaultThemeColor;
  padding: 16px;
  text-align: left;
  height: 60px;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 0;
  border-top-right-radius: 19px;
  position: relative;

  img {
    height: 100%;
  }
}
.sidebar-height {
  height: 100vh;
}
.sidebar {
  height: 100vh;
  width: 85px;
  position: fixed;
  top: 0;
  left: 0;
  // transition: width 0.5s ease;
  padding-top: 0px;
  white-space: nowrap;
  background-color: $sidebarBgColor;
  z-index: 999;
  box-shadow: 2px 2px 3px #ddd;
  border-top-right-radius: 20px;

  .sidebar-body{
    overflow-y: scroll;
    height: calc(100vh - 120px);

    .p-panelmenu-panel:last-child {
      margin-bottom: 20px;
    }
  }

  &.shrink-width {
    width: 95px;

    .event-dropdown {
      width: 0;
      opacity: 0;
      overflow: hidden;
      transition: width 0.5s ease;
    }

    .event-image {
      display: flex;
      justify-content: center; 
      align-items: center; 
      height: 60px;
      position: absolute;
      top: 0;
      left: 29px;

      .event-img {
        width: 24px;
        height: 24px;
        border-radius: 6px;
      }
    }

    .p-menuitem-text {
      padding: 8px 8px 8px 0px;
      font-size: 14px;
      font-weight: 500;
      transition: width 0.5s ease;
      display: none;
    }

    .shrink-width-logo {
      margin-left: 14px;
    }

    .sidebar-closed-logo{
      width: 30px;
      height: 30px;
      background-color: #1E90FF;
      transition: width 0.5s ease;
    }

    .full-width-logo {
      display: none;
    }

    .p-panelmenu-header>a .p-panelmenu-icon {
      display: none;
    }

    .p-panelmenu-header {
        a {
          margin: 0;
        }
    }

    chevronrighticon {
      display: none;
    }

    chevrondownicon {
      display: none;
    }

    .p-dropdown .p-dropdown-label{
      display: none;
    }

    .sidebar-logo{    
      position: absolute;
      top: 0;
      width: 0px;
      // animation: slideThenShrink 0.5s ease backwards;
      background-color: #1E90FF;
    }
  }

  &.full-width {
    width: 275px;


    .shrink-width-logo {
      display: none;
      width: 0px;
      transition: width 0.5s ease;
    }

    .sidebar-logo{
        width: 150px;
        height: 25px;
        background-color: #1E90FF;
        transition: width 0.5s ease;
        position: absolute;
        top: 17px;
        left: 38px;
        // animation: expandThenSlide 0.5s ease forwards;
    }

    .event-image {
      display: none;
    }

    .event-dropdown {
      width: 100%;
      opacity: 1;
      transition: width 0.5s ease;
      display: flex;
      align-items: center;
      height: 60px;
    }

    .p-menuitem-text {
      padding: 8px 8px 8px 0px;
      font-size: 14px;
      font-weight: 500;
      color:#000;
    }

    .p-panelmenu-header {
      a {
        margin: 0 20px;
      }
    }

    .p-dropdown {
      .p-dropdown-trigger{
        width: 0.5rem;
      }
    }
   
  }

  .p-widget-content {
    background-color: $sidebarBgColor;
    border: 0 none;

    .p-menuitem-link {
      border-left: 3px solid $sidebarBgColor;
      color: $sidebarMenuTextColor;
      height: 40px;
      padding: 8px 4px 3px;

      &:hover {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        color: $sidebarMenuHoverTextColor;
      }

      &:active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 0 solid $defaultThemeColor;
        color: $sidebarMenuHoverTextColor;
        padding: 8px 4px 3px;
      }

      .p-menuitem-text {
        font-size: 12px;
      }
    }

    .p-menuitem {

      &:not(.p-state-active) {

        &:hover {
          background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          color: $sidebarMenuHoverTextColor;
        }

        &:active {
          background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          border-left: 3px solid $defaultThemeColor;
          color: $sidebarMenuHoverTextColor;
        }
      }

      .ui-state-active {
        background: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 3px solid $defaultThemeColor;

        .ui-menuitem-text {
          color: $sidebarMenuHoverTextColor;
          font-size: 12px;
        }
      }
    }
  }

  .p-panelmenu {
    .fa {
      color: $sidebarIconColor;
      font-size: 20px;
      margin-right: 12px;
    }

    .p-panelmenu-header {
      .p-menuitem-icon {
        padding: 14px 12px;
        margin: 12px 12px 12px 14px;
    }

      .p-menuitem-link-active {
        background-color: #F5F5F5;
        border-radius: 6px;

        .p-menuitem-text {
          color: $primaryColor;
          font-weight: 500;
        }

        .p-menuitem-icon {
          color: $primaryColor;
          background: $primaryColor;
        }
      }

      &:not(.p-highlight):not(.p-disabled)>a:hover {
        border: none;
        background-color: #F5F5F5;
        border-radius: 6px;
      }

      a {
        border-left: 9px solid transparent;
        color: $sidebarMenuTextColor;
        margin: 0 0 2px 0;
        background: $sidebarBgColor;
        border: none;

        &:hover {
          background: #F5F5F5;
          border-radius: 6px;
        }
      }

      &:active {
        background-color: transparent;
        border-color: #FFF;
        border-radius: 0;

        a {
          color: $sidebarMenuTextColor;
        }
      }

      .p-menuitem-text{
        &:hover{
          color: $primaryColor;
          font-weight: 500;
          background: #F5F5F5;
        }
      }

      .p-state-active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 9px solid $sidebarActiveColor;
        color: $sidebarActiveColor;
      }

      .p-panelmenu-headerlink-hasicon {
        &.ui-state-active {
          background: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          box-shadow: inset 3 0 0 0 rgba(236, 29, 37, 1);
          color: #FFF;
        }

        &:hover {
          background-color: $sideBarHoverBgColor;
          border-top: 0;
          box-shadow: inset 3px 0 0 0 rgba(236, 29, 37, 1);
        }
      }
    }
  }

  a {
    color: $sidebarMenuHoverTextColor;
  }

  .p-state-default {
    background: $sidebarBgColor;
    border: 1px solid $sidebarBgColor;
    color: $sidebarMenuTextColor;
  }

  .fa-caret-right,
  .fa-caret-down {
    &::before {
      content: '';
    }
  }

  .p-panelmenu-icon {
    &.fa {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 1600px) {
    .left-panel {
      width: 15%;
    }

    .right-panel {
      width: 85%;
    }
  }

  @media screen and (max-width: 1600px) and (min-width: 1200px) {
    .left-panel {
      width: 20%;
    }

    .right-panel {
      width: 80%;
    }
  }


  @media screen and (max-width: 1200px) and (min-width: 1000px) {
    .left-panel {
      width: 22%;
    }

    .right-panel {
      width: 78%;
    }
  }

  .sidebar-panel-menu {

    .p-panelmenu-header-link {
      &:hover {
        .mi-lg {
          background:$primaryColor;
        }

        .p-menuitem-text {
          color: $primaryColor;
        }
      }
    }

    .p-panelmenu .p-panelmenu-content {
      border: 0;
    }

    .p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
      margin-right: 0rem;
      position: absolute;
      right: 16px;
    }

    .p-panelmenu .p-menuitem-link {
      left: 20px;
      position: relative;
      .p-menuitem-link-active {
        background-color: #F5F5F5;
        border-radius: 6px;

        .p-menuitem-text {
          color: $primaryColor;
          font-weight: 500;
        }

        .p-menuitem-icon {
          color: $primaryColor;
          background: $primaryColor;
        }
      }
    }

  }

  chevronrighticon {
    &::before {
      content: url('../../../../../assets/images/mi-theme/light/arrow/chevron_down.svg');
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 16px;
      left: 198px;
    }
      .p-icon{
        display: none;
      }
  }

  chevrondownicon{
    &::before {
      content: url('../../../../../assets/images/mi-theme/light/arrow/chevron_up.svg');
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 16px;
      left: 198px;
    }
      .p-icon{
        display: none;
      }
  }

  .p-panelmenu {
    .p-panelmenu-header {
      .p-panelmenu-header-content {
        border: none;
        margin: 0 22px;
        border-radius: 6px;
        

        .p-panelmenu-header-action {
          padding: 0 0 2px 0px;
        }
      }
    }
  }

  .dashboard-child{
    margin-top: 1rem;
  }
}
.sidebar-dropdown{
  height: 60px;
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 10px;
  position: relative;

  .p-overlay {
    top: 33px !important;
  }

  p-dropdown{
    margin: 0 0 0 16px;
    width: 88%;

    .p-dropdown-panel {
      .p-dropdown-items {
        .p-dropdown-item {
          min-width: 225px;
        }
      }
    }
  }

  .p-dropdown{
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 1.25rem;
    border: none;
    width: 100%;
  }

  chevrondownicon{
    &::before {
      content: url('../../../../../assets/images/mi-theme/light/arrow/chevron_down.svg');
      display: inline-block;
      position: absolute;
      right: 2px;
      top: 9px;
    }
      
  }

}

.p-panelmenu{
  .p-submenu-list{
    .p-menuitem-content {
      &:hover{
        background-color: #F5F5F5;
      }
      a {
        .p-menuitem-text{
          color: #262C2D;
          font-weight: 600;
          &:hover {
            color: $primaryColor;
            font-weight: 600;
          }
        }
      }
      .p-menuitem-link-active {
        .p-menuitem-text {
          color: $primaryColor;

          &:hover {
            color: $primaryColor;
          }
        }
        .p-menuitem-icon{
          background-color: $primaryColor;

          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
} 

.mi-manage-groups{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
  &:active{
    background-color: $primaryColor;
  }
}

.mi-manage-shift{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-shift-calendar{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-shift-template{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-manage-roles{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-manage-users{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-booking-history{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-current-booking{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-all-drivers{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-attendance-management{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-leave-management{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-about-us{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-faq{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-terms-conditions{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-privacy-policy{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

.mi-contact-support{
  height: 20px;
  width: 20px;
  margin: 12px 12px 12px 20px;
}

@keyframes slideThenShrink {
  0% {
    left: 38px;
    width: 150px;
  }
  35% {
    left: -50px;
    width: 150px;
  }
  65% {
    left: -150px;
    width: 150px;
  }
  100% {
    left: -300px;
    width: 0;
  }
}

@keyframes expandThenSlide {
  0% {
    left: -300px;
    width: 0;
  }
  35% {
    left: -250px;
    width: 150px;
  }
  65% {
    left: -150px;
    width: 150px;
  }
  100% {
    left: 38px;
    width: 150px;
  }
}