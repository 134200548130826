.mi {
  width: 16px;
  height: 16px;
  background-size: cover;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: $iconPrimaryBG;

  &:hover {
    background-color: $primaryColor;
  }
}

.mi-lg {
  height: 100%;
  background-size: cover;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: $iconPrimaryBG;

  &:hover {
    background-color: $primaryColor;
  }

  &.wh-24 {
    width: 24px;
    height: 24px;
  }

  &.wh-10 {
    width: 10px;
    height: 10px;
  }

  &.wh-12 {
    width: 12px;
    height: 12px;
  }

  &.wh-122 {
    width: 12.2px;
    height: 12.2px;
  }

  &.wh-142 {
    width: 14.2px;
    height: 14.2px;
  }

  &.wh-13 {
    width: 13px;
    height: 13px;
  }

  &.wh-14 {
    width: 14px;
    height: 14px;
  }

  &.wh-15 {
    width: 15px;
    height: 15px;
  }

  &.wh-16 {
    width: 16px;
    height: 16px;
  }

  &.wh-17 {
    width: 17px;
    height: 17px;
  }

  &.wh-18 {
    width: 18px;
    height: 18px;
  }

  &.wh-20 {
    width: 20px;
    height: 20px;
  }

  &.wh-21 {
    width: 21px;
    height: 21px;
  }

  &.wh-30 {
    width: 30px;
    height: 30px;
  }

  &.wh-22 {
    width: 22px;
    height: 22px;
  }

  &.wh-28 {
    width: 28px;
    height: 28px;
  }

  &.wh-34 {
    width: 34px;
    height: 34px;
  }

  &.wh-40 {
    width: 40px;
    height: 32px;
  }

  &.wh-20-45 {
    width: 20px;
    height: 45px;
  }

  &.wh-80 {
    width: 80px;
    height: 81px;
  }

  &.wh-25-20 {
    width: 25px;
    height: 20px;
  }
}

.mi-xl {
  width: 32px;
  height: 32px;
  background-size: cover;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: $iconPrimaryBG;

  &:hover {
    background-color: $primaryColor;
  }
}

.mib-lg {
  height: 100%;
  background-size: cover;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &.wh-24 {
    width: 24px;
    height: 24px;
  }
}

.mi-home {
  mask-image: url("#{$base}/nav/Home.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Home.svg");
  }
}

.mi-booking, .mi-bookings {
  mask-image: url("#{$base}/nav/Booking.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Booking.svg");
  }
}

.mi-dispatch {
  mask-image: url("#{$base}/nav/Dispatch.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Dispatch.svg");
  }
}

.mi-passenger {
  mask-image: url("#{$base}/nav/Passanger.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Passanger.svg");
  }
}

.mi-vehicle {
  mask-image: url("#{$base}/nav/Vehicle.svg");

  &:hover {
    background-image: url("#{$base}/nav/Vehicle_Hover.svg");
  }
}

.mi-driver {
  mask-image: url("#{$base}/nav/Driver.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Driver.svg");
  }
}

.driver {
  mask-image: url("#{$base}/nav/Driver.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Driver.svg");
  }
}

.mi-concerns {
  mask-image: url("#{$base}/nav/Incidents _complaints.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Incidents _complaints.svg");
  }
}

.mi-manage-locations {
  mask-image: url("#{$base}/nav/Manage_location.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Manage_location.svg");
  }
}

.mi-manage-zones {
  mask-image: url("#{$base}/nav/Manage_zones.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Manage_zones.svg");
  }
}

.mi-service {
  mask-image: url("#{$base}/nav/Service.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Service.svg");
  }
}

.mi-reminder {
  mask-image: url("#{$base}/nav/Reminder.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Reminder.svg");
  }
}

.mi-expense-history {
  mask-image: url("#{$base}/nav/Expense_History.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Expense_History.svg");
  }
}

.mi-equipment {
  mask-image: url("#{$base}/nav/Equipment.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Equipment.svg");
  }
}

.mi-vehicle-inspection {
  mask-image: url("#{$base}/nav/Vehicle_Inspection.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Vehicle_Inspection.svg");
  }
}

.mi-issues {
  mask-image: url("#{$base}/nav/Issues.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Issues.svg");
  }
}

.mi-document {
  mask-image: url("#{$base}/nav/Document.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Document.svg");
  }
}

.mi-fuel-history {
  mask-image: url("#{$base}/nav/Fuel_history.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Fuel_history.svg");
  }
}

.mi-vendors {
  mask-image: url("#{$base}/nav/Vendor.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Vendor.svg");
  }
}

.mi-shift-management, .mi-shift {
  mask-image: url("#{$base}/nav/Shift_Manage.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Shift_Manage.svg");
  }
}

.mi-reports {
  mask-image: url("#{$base}/nav/Document.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Document.svg");
  }
}

.mi-training {
  mask-image: url("#{$base}/nav/Document.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Document.svg");
  }
}

.mi-knowledge {
  mask-image: url("#{$base}/nav/Knowlege.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Knowlege.svg");
  }
}

.mi-user-management, .mi-users {
  mask-image: url("#{$base}/nav/Document.svg");

  &:hover {
    mask-image: url("#{$base}/nav/Document.svg");
  }
}

.mi-map {
  mask-image: url("#{$base}/basic/location_outline_black.svg");

  &:hover {
    mask-image: url("#{$base}/basic/location_outline_red.svg");
  }
}

.mi-heat-map {
  mask-image: url("#{$base}/basic/location_outline_black.svg");

  &:hover {
    mask-image: url("#{$base}/basic/location_outline_red.svg");
  }
}

.mi-activity-feed {
  mask-image: url("#{$base}/basic/location_outline_black.svg");

  &:hover {
    mask-image: url("#{$base}/basic/location_outline_red.svg");
  }
}

.mi-print {
  mask-image: url("#{$base}/basic/print.svg");
}

.mi-settings_future {
  mask-image: url("#{$base}/basic/settings_future.svg");
}

.mi-share {
  mask-image: url("#{$base}/basic/share.svg");
}

.mi-share_outline {
  mask-image: url("#{$base}/basic/share_outline.svg");
}

.mi-slider_01,
.specs {
  mask-image: url("#{$base}/basic/slider_01.svg");
}

.mi-slider_02 {
  mask-image: url("#{$base}/basic/slider_02.svg");
}

.mi-slider_03 {
  mask-image: url("#{$base}/basic/slider_03.svg");
}

.mi-stopwatch {
  mask-image: url("#{$base}/basic/stopwatch.svg");
}

.mi-subtract {
  mask-image: url("#{$base}/basic/subtract.svg");
}

.mi-sun {
  mask-image: url("#{$base}/basic/sun.svg");
}

.mi-trash_full {
  mask-image: url("#{$base}/basic/trash_full.svg");
}

.mi-unlink {
  mask-image: url("#{$base}/basic/unlink.svg");
}

.mi-add {
  mask-image: url("#{$base}/basic/add.svg");
}

.mi-alarm {
  mask-image: url("#{$base}/basic/alarm.svg");
}

.mi-alarm_add {
  mask-image: url("#{$base}/basic/alarm_add.svg");
}

.mi-archive_3 {
  mask-image: url("#{$base}/basic/archive_3.svg");
}

.mi-archive_4 {
  mask-image: url("#{$base}/basic/archive_4.svg");
}

.mi-attach {
  mask-image: url("#{$base}/basic/attach.svg");
}

.mi-bulb {
  mask-image: url("#{$base}/basic/bulb.svg");
}

.mi-checkbox {
  mask-image: url("#{$base}/basic/checkbox.svg");
}

.mi-checkbox_checked_2 {
  mask-image: url("#{$base}/basic/checkbox_checked 2.svg");
}

.mi-checkbox_checked {
  mask-image: url("#{$base}/basic/checkbox_checked.svg");
}

.mi-checkbox_square {
  mask-image: url("#{$base}/basic/checkbox_square.svg");
}

.mi-circle_check {
  mask-image: url("#{$base}/basic/circle_check.svg");
}

.mi-circle_check_outline {
  mask-image: url("#{$base}/basic/circle_check_outline.svg");
}

.circle_check_outline {
  mask-image: url("#{$base}/basic/circle_check_outline.svg");
}

.mi-circle_checked {
  mask-image: url("#{$base}/basic/circle_checked.svg");
}

.mi-clock {
  mask-image: url("#{$base}/basic/clock.svg");
}

.mi-cookie {
  mask-image: url("#{$base}/basic/cookie.svg");
}

.mi-command {
  mask-image: url("#{$base}/basic/command.svg");
}

.mi-confused {
  mask-image: url("#{$base}/basic/confused.svg");
}

.mi-credit_card {
  mask-image: url("#{$base}/basic/credit_card.svg");
}

.mi-credit_card_alt {
  mask-image: url("#{$base}/basic/credit_card_alt.svg");
}

.mi-done {
  mask-image: url("#{$base}/basic/done.svg");
}

.mi-done_all {
  mask-image: url("#{$base}/basic/done_all.svg");
}

.mi-download {
  mask-image: url("#{$base}/basic/download.svg");
}

.mi-download_done {
  mask-image: url("#{$base}/basic/download_done.svg");
}

.mi-edit_3 {
  mask-image: url("#{$base}/basic/edit_3.svg");
}

.mi-ellipse {
  mask-image: url("#{$base}/basic/ellipse.svg");
}

.mi-exit {
  mask-image: url("#{$base}/basic/exit.svg");
}

.mi-external_link {
  mask-image: url("#{$base}/basic/external_link.svg");
}

.mi-filter_2 {
  mask-image: url("#{$base}/basic/filter_2.svg");
}

.mi-filter_selected {
  mask-image: url("#{$base}/basic/filter_selected.svg");
}

.mi-happy {
  mask-image: url("#{$base}/basic/happy.svg");
}

.mi-heart_fill {
  mask-image: url("#{$base}/basic/heart_fill.svg");
}

.mi-heart_outline {
  mask-image: url("#{$base}/basic/heart_outline.svg");
}

.mi-help_circle {
  mask-image: url("#{$base}/basic/help_circle.svg");
}

.mi-help_circle_outline {
  mask-image: url("#{$base}/basic/help_circle_outline.svg");
}

.mi-help_question_mark {
  mask-image: url("#{$base}/basic/help_questionmark.svg");
}

.mi-image_alt {
  mask-image: url("#{$base}/basic/image_alt.svg");
}

.mi-label {
  mask-image: url("#{$base}/basic/label.svg");
}

.mi-layers {
  mask-image: url("#{$base}/basic/layers.svg");
}

.mi-layers_alt {
  mask-image: url("#{$base}/basic/layers_alt.svg");
}

.mi-link {
  mask-image: url("#{$base}/basic/link.svg");
}

.mi-link_02 {
  mask-image: url("#{$base}/basic/link_02.svg");
}

.mi-loading {
  mask-image: url("#{$base}/basic/loading.svg");
}

.mi-locate_me {
  mask-image: url("#{$base}/basic/locate_me.svg");
}

.mi-password {
  mask-image: url("#{$base}/basic/password.svg");
}

.mi-location {
  mask-image: url("#{$base}/basic/location.svg");
}

.mi-location_outline {
  mask-image: url("#{$base}/basic/location_outline.svg");
}

.mi-location_outline_black {
  mask-image: url("#{$base}/basic/location_outline_black.svg");
}

.mi-location_outline_red {
  mask-image: url("#{$base}/basic/location_outline_red.svg");
}

.mi-log_out {
  mask-image: url("#{$base}/basic/log_out.svg");
}

.mi-mail {
  mask-image: url("#{$base}/basic/mail.svg");
}

.mi-mail_open {
  mask-image: url("#{$base}/basic/mail_open.svg");
}

.mi-map_2 {
  mask-image: url("#{$base}/basic/map_2.svg");
}

.mi-map {
  mask-image: url("#{$base}/basic/map.svg");
}

.mi-moon {
  mask-image: url("#{$base}/basic/moon.svg");
}

.mi-off_close {
  mask-image: url("#{$base}/basic/off_close.svg");
}

.mi-off_outline_close {
  mask-image: url("#{$base}/basic/off_outline_close.svg");
}

.off_outline_close {
  mask-image: url("#{$base}/basic/off_outline_close.svg");
}

.mi-path {
  mask-image: url("#{$base}/basic/path.svg");
}

.mi-phone {
  mask-image: url("#{$base}/basic/phone.svg");
}

.mi-radio_filled {
  mask-image: url("#{$base}/basic/radio_filled.svg");
}

.mi-radio {
  mask-image: url("#{$base}/basic/radio.svg");
}

.mi-radio_filled_2 {
  mask-image: url("#{$base}/basic/radio_filled_2.svg");
}

.mi-refresh {
  mask-image: url("#{$base}/basic/refresh.svg");
}

.mi-refresh_02 {
  mask-image: url("#{$base}/basic/refresh_02.svg");
}

.mi-sad {
  mask-image: url("#{$base}/basic/sad.svg");
}

.mi-settings,
.privilege_settings {
  mask-image: url("#{$base}/basic/settings.svg");
}

.mi-settings_filled {
  mask-image: url("#{$base}/basic/settings_filled.svg");
}

.mi-no_priority {
  mask-image: url("#{$base}/arrow/priority/no_priority.svg");
}

.critical {
  mask-image: url("#{$base}/arrow/priority/critical.svg");
}

.high {
  mask-image: url("#{$base}/arrow/priority/high.svg");
}

.low {
  mask-image: url("#{$base}/arrow/priority/low.svg");
}

.medium {
  mask-image: url("#{$base}/arrow/priority/medium.svg");
}

.thin-big-down {
  mask-image: url("#{$base}/arrow/thin_big_down.svg");
}

.thin-big-left {
  mask-image: url("#{$base}/arrow/thin_big_left.svg");
}

.thin-big-right {
  mask-image: url("#{$base}/arrow/thin_big_right.svg");
}

.thin-big-up {
  mask-image: url("#{$base}/arrow/thin_big_up.svg");
}

.thin-long-02-down {
  mask-image: url("#{$base}/arrow/thin_long_02_down.svg");
}

.thin-long-02-left {
  mask-image: url("#{$base}/arrow/thin_long_02_left.svg");
}

.thin-long-02-right {
  mask-image: url("#{$base}/arrow/thin_long_02_right.svg");
}

.thin-long-02-up {
  mask-image: url("#{$base}/arrow/thin_long_02_up.svg");
}

.thin-long-down {
  mask-image: url("#{$base}/arrow/thin_long_down.svg");
}

.thin-long-left {
  mask-image: url("#{$base}/arrow/thin_long_left.svg");
}

.thin-long-right {
  mask-image: url("#{$base}/arrow/thin_long_right.svg");
}

.thin-long-up {
  mask-image: url("#{$base}/arrow/thin_long_up.svg");
}

.unfold-less {
  mask-image: url("#{$base}/arrow/unfold_less.svg");
}

.unfold-more {
  mask-image: url("#{$base}/arrow/unfold_more.svg");
}

.caret-down-2 {
  mask-image: url("#{$base}/arrow/caret_down_2.svg");
}

.caret-down {
  mask-image: url("#{$base}/arrow/caret_down.svg");
}

.caret-left {
  mask-image: url("#{$base}/arrow/caret_left.svg");
}

.caret-right-2 {
  mask-image: url("#{$base}/arrow/caret_right_2.svg");
}

.caret-right {
  mask-image: url("#{$base}/arrow/caret_right.svg");
}

.caret-up {
  mask-image: url("#{$base}/arrow/caret_up.svg");
}

.chevron-big-down {
  mask-image: url("#{$base}/arrow/chevron_big_down.svg");
}

.chevron-big-left {
  mask-image: url("#{$base}/arrow/chevron_big_left.svg");
}

.chevron-big-right {
  mask-image: url("#{$base}/arrow/chevron_big_right.svg");
}

.chevron-big-up {
  mask-image: url("#{$base}/arrow/chevron_big_up.svg");
}

.chevron-down {
  mask-image: url("#{$base}/arrow/chevron_down.svg");
}

.chevron-duo-down {
  mask-image: url("#{$base}/arrow/chevron_duo_down.svg");
}

.chevron-duo-left {
  mask-image: url("#{$base}/arrow/chevron_duo_left.svg");
}

.chevron-duo-right {
  mask-image: url("#{$base}/arrow/chevron_duo_right.svg");
}

.chevron-duo-up {
  mask-image: url("#{$base}/arrow/chevron_duo_up.svg");
}

.chevron-left {
  mask-image: url("#{$base}/arrow/chevron_left.svg");
}

.chevron-right {
  mask-image: url("#{$base}/arrow/chevron_right.svg");
}

.chevron-up {
  mask-image: url("#{$base}/arrow/chevron_up.svg");
}

.circle-chevron-down {
  mask-image: url("#{$base}/arrow/circle_chevron_down.svg");
}

.circle-chevron-left {
  mask-image: url("#{$base}/arrow/circle_chevron_left.svg");
}

.circle-chevron-right {
  mask-image: url("#{$base}/arrow/circle_chevron_right.svg");
}

.circle-chevron-up {
  mask-image: url("#{$base}/arrow/circle_chevron_up.svg");
}

.circle-down {
  mask-image: url("#{$base}/arrow/circle_down.svg");
}

.circle-left-filled {
  mask-image: url("#{$base}/arrow/circle_left_filled.svg");
}

.circle-left {
  mask-image: url("#{$base}/arrow/circle_left.svg");
}

.circle-right {
  mask-image: url("#{$base}/arrow/circle_right.svg");
}

.circle-up {
  mask-image: url("#{$base}/arrow/circle_up.svg");
}

.expand {
  mask-image: url("#{$base}/arrow/expand.svg");
}

.first-page {
  mask-image: url("#{$base}/arrow/first_page.svg");
}

.forward {
  mask-image: url("#{$base}/arrow/Forward.svg");
}

.last-page {
  mask-image: url("#{$base}/arrow/last_page.svg");
}

.long-bottom-down {
  mask-image: url("#{$base}/arrow/long_bottom_down.svg");
}

.long-bottom-up {
  mask-image: url("#{$base}/arrow/long_bottom_up.svg");
}

.long-down {
  mask-image: url("#{$base}/arrow/long_down.svg");
}

.long-left {
  mask-image: url("#{$base}/arrow/long_left.svg");
}

.long-right {
  mask-image: url("#{$base}/arrow/long_right.svg");
}

.long-up {
  mask-image: url("#{$base}/arrow/long_up.svg");
}

.long-up-left- {
  mask-image: url("#{$base}/arrow/long_up_left_.svg");
}

.long-up-right {
  mask-image: url("#{$base}/arrow/long_up_right.svg");
}

.reply {
  mask-image: url("#{$base}/arrow/Reply.svg");
}

.sent {
  mask-image: url("#{$base}/arrow/sent.svg");
}

.short-down {
  mask-image: url("#{$base}/arrow/short_down.svg");
}

.short-left {
  mask-image: url("#{$base}/arrow/short_left.svg");
}

.short-right {
  mask-image: url("#{$base}/arrow/short_right.svg");
}

.short-up {
  mask-image: url("#{$base}/arrow/short_up.svg");
}

.shrink {
  mask-image: url("#{$base}/arrow/shrink.svg");
}

.small-long-down {
  mask-image: url("#{$base}/arrow/small_long_down.svg");
}

.small-long-left {
  mask-image: url("#{$base}/arrow/small_long_left.svg");
}

.small-long-right {
  mask-image: url("#{$base}/arrow/small_long_right.svg");
}

.small-long-up {
  mask-image: url("#{$base}/arrow/small_long_up.svg");
}

.sub-left {
  mask-image: url("#{$base}/arrow/sub_left.svg");
}

.sub-right {
  mask-image: url("#{$base}/arrow/sub_right.svg");
}

.add-multiple {
  mask-image: url("#{$base}/arrow/add-multiple.svg");
}

.upload {
  mask-image: url("#{$base}/arrow/upload.svg");
}

.download {
  mask-image: url("#{$base}/arrow/download.svg");
}

.export {
  mask-image: url("#{$base}/arrow/export.svg");
}

.details,
.overview,
.create_booking {
  mask-image: url("#{$base}/basic/details.svg");
}

.documents {
  mask-image: url("#{$base}/nav/Document.svg");
}

.life_span {
  mask-image: url("#{$base}/basic/refresh_02.svg");
}

.financial {
  mask-image: url("#{$base}/basic/financial.svg");
}

.features {
  mask-image: url("#{$base}/basic/feature.svg");
}

.specifications {
  mask-image: url("#{$base}/basic/specifications.svg");
}

.basic-circle-check {
  mask-image: url("#{$base}/basic/basic_circle_check.svg");
}

.user {
  mask-image: url("#{$base}/user/user.svg");
}

.service-history {
  mask-image: url("#{$base}/basic/service_history.svg");
}

.inspection-history {
  mask-image: url("#{$base}/basic/inspection_history.svg");
}

.journey-history {
  mask-image: url("#{$base}/basic/journey_history.svg");
}

.assignment-history {
  mask-image: url("#{$base}/basic/assignment_history.svg");
}

.edit_pencil {
  mask-image: url("#{$base}/basic/edit_pencil.svg");
}

.issues-icon {
  mask-image: url("#{$base}/basic/issues_icon.svg");
}

.events {
  mask-image: url("#{$base}/basic/event.svg");
}

.basic-delete {
  mask-image: url("#{$base}/basic/basic_delete.svg");
}

.basic-delete-red {
  mask-image: url("#{$base}/basic/basic_delete_red.svg");
}

.basic-location {
  mask-image: url("#{$base}/basic/basic_location.svg");
}

.overdue {
  mask-image: url("#{$base}/attention/overdue.svg");
}

.warning {
  mask-image: url("#{$base}/attention/warning.svg");
}

.warning_outline {
  mask-image: url("#{$base}/attention/warning_outline.svg");
}

.error {
  mask-image: url("#{$base}/attention/error.svg");
}

.info_circle {
  mask-image: url("#{$base}/attention/info_circle.svg");
}

.info_circle_outline {
  mask-image: url("#{$base}/attention/info_circle_outline.svg");
}

.info_square {
  mask-image: url("#{$base}/attention/info_square.svg");
}

.info_square_outline {
  mask-image: url("#{$base}/attention/info_square_outline.svg");
}

.calendar_minus {
  mask-image: url("#{$base}/calendar/calendar_minus.svg");
}

.calendar_plus {
  mask-image: url("#{$base}/calendar/calendar_plus.svg");
}

.calendar_week {
  mask-image: url("#{$base}/calendar/calendar_week.svg");
}

.calendar_x {
  mask-image: url("#{$base}/calendar/calendar_x.svg");
}

.expired {
  mask-image: url("#{$base}/calendar/expired.svg");
}

.calendar_date_and_time {
  mask-image: url("#{$base}/calendar/calendar_date_and_time.svg");
}

.calendar {
  mask-image: url("#{$base}/calendar/calendar.svg");
}

.calendar_calendar {
  mask-image: url("#{$base}/calendar/calendar_calendar.svg");
}

.calendar_check {
  mask-image: url("#{$base}/calendar/calendar_check.svg");
}

.calendar_edit {
  mask-image: url("#{$base}/calendar/calendar_edit.svg");
}

.calendar_event {
  mask-image: url("#{$base}/calendar/calendar_event.svg");
}

.grid_round {
  mask-image: url("#{$base}/grid/grid_round.svg");
}

.grid_small {
  mask-image: url("#{$base}/grid/grid_small.svg");
}

.grid_small_round {
  mask-image: url("#{$base}/grid/grid_small_round.svg");
}

.grid_vertical {
  mask-image: url("#{$base}/grid/grid_vertical.svg");
}

.grid_vertical_round {
  mask-image: url("#{$base}/grid/grid_vertical_round.svg");
}

.columns {
  mask-image: url("#{$base}/grid/columns.svg");
}

.dashboard_02 {
  mask-image: url("#{$base}/grid/dashboard_02.svg");
}

.grid-o {
  mask-image: url("#{$base}/grid/grid.svg");
}

.grid_big {
  mask-image: url("#{$base}/grid/grid_big.svg");
}

.grid_big_round {
  mask-image: url("#{$base}/grid/grid_big_round.svg");
}

.grid_horizontal {
  mask-image: url("#{$base}/grid/grid_horizontal.svg");
}

.grid_horizontal_round {
  mask-image: url("#{$base}/grid/grid_horizontal_round.svg");
}

.menu_alt_03 {
  mask-image: url("#{$base}/menu/menu_alt_03.svg");
}

.menu_alt_04 {
  mask-image: url("#{$base}/menu/menu_alt_04.svg");
}

.menu_alt_05 {
  mask-image: url("#{$base}/menu/menu_alt_05.svg");
}

.menu_duo {
  mask-image: url("#{$base}/menu/menu_duo.svg");
}

.more_horizontal {
  mask-image: url("#{$base}/menu/more_horizontal.svg");
}

.more_vertical {
  mask-image: url("#{$base}/menu/more_vertical.svg");
}

.close_big {
  mask-image: url("#{$base}/menu/close_big.svg");
}

.close_small {
  mask-image: url("#{$base}/menu/close_small.svg");
}

.hamburger_1 {
  mask-image: url("#{$base}/menu/hamburger_1.svg");
}

.hamburger_2 {
  mask-image: url("#{$base}/menu/hamburger_2.svg");
}

.menu_alt_01 {
  mask-image: url("#{$base}/menu/menu_alt_01.svg");
}

.menu_alt_02 {
  mask-image: url("#{$base}/menu/menu_alt_02.svg");
}

.notification_outline_dot {
  mask-image: url("#{$base}/notification/notification_outline_dot.svg");
}

.notification_outline_minus {
  mask-image: url("#{$base}/notification/notification_outline_minus.svg");
}

.notification_outline_plus {
  mask-image: url("#{$base}/notification/notification_outline_plus.svg");
}

.notification_plus {
  mask-image: url("#{$base}/notification/notification_plus.svg");
}

.notification {
  mask-image: url("#{$base}/notification/notification.svg");
}

.notification_active {
  mask-image: url("#{$base}/notification/notification_active.svg");
}

.notification_deactivated {
  mask-image: url("#{$base}/notification/notification_deactivated.svg");
}

.notification_dot {
  mask-image: url("#{$base}/notification/notification_dot.svg");
}

.notification_minus {
  mask-image: url("#{$base}/notification/notification_minus.svg");
}

.notification_outline {
  mask-image: url("#{$base}/notification/notification_outline.svg");
}

.reconfirmed {
  mask-image: url("#{$base}/user/reconfirmed.svg");
}

.sms {
  mask-image: url("#{$base}/user/sms.svg");
}

.user_1 {
  mask-image: url("#{$base}/user/user_1.svg");
}

.user_add {
  mask-image: url("#{$base}/user/user_add.svg");
}

.user_check {
  mask-image: url("#{$base}/user/user_check.svg");
}

.user_circle {
  mask-image: url("#{$base}/user/user_circle.svg");
}

.user_minus {
  mask-image: url("#{$base}/user/user_minus.svg");
}

.user_pin {
  mask-image: url("#{$base}/user/user_pin.svg");
}

.user_plus {
  mask-image: url("#{$base}/user/user_plus.svg");
}

.user_voice {
  mask-image: url("#{$base}/user/user_voice.svg");
}

.user_x {
  mask-image: url("#{$base}/user/user_x.svg");
}

.driver_arrived {
  mask-image: url("#{$base}/user/driver_arrived.svg");
}

.default_driver {
  mask-image: url("#{$base}/user/default_driver.svg");
}

.id_card {
  mask-image: url("#{$base}/user/id_card.svg");
}

.import {
  mask-image: url("#{$base}/user/import.svg");
}

.on_the_way {
  mask-image: url("#{$base}/user/on_the_way.svg");
}

.on_trip {
  mask-image: url("#{$base}/user/on_trip.svg");
}

.save {
  mask-image: url("#{$base}/save.svg");
}

.settings_overlay {
  mask-image: url("#{$base}/settings_overlay.svg");
}

.shift {
  mask-image: url("#{$base}/shift.svg");
}

.snooze {
  mask-image: url("#{$base}/snooze.svg");
}

.steering_wheel {
  mask-image: url("#{$base}/steering_wheel.svg");
}

.add_lessons {
  mask-image: url("#{$base}/add_lessons.svg");
}

.add_lessons_1 {
  mask-image: url("#{$base}/add_lessons_1.svg");
}

.add_to_list {
  mask-image: url("#{$base}/add_to_list.svg");
}

.basic_tray {
  mask-image: url("#{$base}/basic_tray.svg");
}

.calender_new {
  mask-image: url("#{$base}/calender_new.svg");
}

.clock {
  mask-image: url("#{$base}/clock.svg");
}

.danger_1 {
  mask-image: url("#{$base}/danger_1.svg");
}

.day_off {
  mask-image: url("#{$base}/day_off.svg");
}

.empty_grid {
  mask-image: url("#{$base}/empty_grid.svg");
}

.group_pin {
  mask-image: url("#{$base}/group_pin.svg");
}

.in_progress {
  mask-image: url("#{$base}/in_progress.svg");
}

.in_progress_1 {
  mask-image: url("#{$base}/in_progress_1.svg");
}

.notification-red {
  mask-image: url("#{$base}/notification_red.svg");
}

.publish {
  mask-image: url("#{$base}/publish.svg");
}

.camera {
  mask-image: url("#{$base}/communication/camera.svg");
}

.video {
  mask-image: url("#{$base}/communication/video.svg");
}

.phone-white {
  mask-image: url("#{$base}/communication/phone_white.svg");
}

.search {
  mask-image: url("#{$base}/basic/search.svg");
}

.user-group {
  mask-image: url("#{$base}/communication/user_group.svg");
}

.text-align {
  mask-image: url("#{$base}/basic/text_align.svg");
}

.plus {
  mask-image: url("#{$base}/basic/plus.svg");
}

.repeat {
  mask-image: url("#{$base}/basic/repeat.svg");
}

.archived_search {
  mask-image: url("#{$base}/basic/archived_search.svg");
}

.back_arrow {
  mask-image: url("#{$base}/basic/back_arrow.svg");
}

.edit_2 {
  mask-image: url("#{$base}/basic/edit_2.svg");
}

.basic_edit_2 {
  mask-image: url("#{$base}/basic/basic_edit_2.svg");
}

.basic_unlink {
  mask-image: url("#{$base}/basic/basic_unlink.svg");
}

.user-on-trip {
  mask-image: url("#{$base}/user_on_trip.svg");
}

.mi-logo {
  mask-image: url("#{$base}/mi_logo.svg");
}

.booking_on_trip {
  mask-image: url("#{$base}/user_on_trip.svg");
}

.user-on-the-way {
  mask-image: url("#{$base}/user_on_the_way.svg");
}

.booking_on_the_way {
  mask-image: url("#{$base}/user_on_the_way.svg");
}

.icon-star-outline {
  mask-image: url("#{$base}/icon_star_outline.svg");
}

.booking_new_request {
  mask-image: url("#{$base}/icon_star_outline.svg");
}

.icon-star-filled {
  mask-image: url("#{$base}/icon_star_filled.svg");
}

.mi-chart-barcode {
  mask-image: url("#{$base}/booking/chart_barcode.svg");
}

.mi-gender {
  mask-image: url("#{$base}/booking/gender.svg");
}

.mi-luggages {
  mask-image: url("#{$base}/booking/luggages.svg");
}

.mi-additional {
  mask-image: url("#{$base}/booking/additionals.svg");
}

.mi-lock {
  mask-image: url("#{$base}/booking/lock.svg");
}

.mi-instructions {
  mask-image: url("#{$base}/booking/instructions.svg");
}

.mi-passenger-booking {
  mask-image: url("#{$base}/booking/passenger.svg");
}


.mi-airport {
  mask-image: url("#{$base}/booking/airport.svg");
}

.mi-flight-details {
  mask-image: url("#{$base}/booking/flight_details.svg");
}

.mi-cityride {
  mask-image: url("#{$base}/booking/cityride.svg");
}

.mi-outstation {
  mask-image: url("#{$base}/booking/outstation.svg");
}

.mi-dedicated {
  mask-image: url("#{$base}/booking/dedicated.svg");
}

.mi-bus {
  mask-image: url("#{$base}/booking/bus.svg");
}

.mi-car {
  mask-image: url("#{$base}/booking/car.svg");
}

.mi-hotel {
  mask-image: url("#{$base}/booking/hotel.svg");
}

.mi-edit-circle {
  mask-image: url("#{$base}/booking/edit_plus_circle.svg");
}

.mi-complete_icon_with_stop {
  background-image: url(#{$base}/booking/complete_icon_with_stop.svg);
  background-size: 32px 47px;
  background-repeat: no-repeat;
}

.mi-complete_icon_without_stop {
  background-size: 32px 47px;
  background-repeat: no-repeat;
  background-image: url("#{$base}/booking/complete_icon_without_stop.svg");
}

.mi-dotted_line {
  mask-image: url("#{$base}/booking/dotted_line.svg");
}

.mi-drop {
  mask-image: url("#{$base}/booking/drop.svg");
}

.mi-notes {
  mask-image: url("#{$base}/booking/notes.svg");
}

.mi-pickup {
  mask-image: url("#{$base}/booking/pickup.svg");
}

.mi-file-minus {
  mask-image: url("#{$base}/booking/file_minus.svg");
}


.mi-private_ride {
  mask-image: url("#{$base}/booking/private_ride.svg");
}

.mi-shared_ride {
  mask-image: url("#{$base}/booking/shared_ride.svg");
}

.mi-shuttle-old {
  mask-image: url("#{$base}/booking/shuttle.svg");
}

.mi-stops {
  mask-image: url("#{$base}/booking/stops.svg");
}

.mi-booking_user {
  mask-image: url("#{$base}/booking/user.svg");
}

.mi-watchlist {
  mask-image: url("#{$base}/booking/watchlist.svg");
}

.mi-additional_vehicle {
  mask-image: url("#{$base}/booking/additional_vehicle.svg");
}

.mi-pickup-point {
  background-image: url("#{$base}/booking/pickup_point.svg");
}

.mi-dropoff-point {
  background-image: url("#{$base}/booking/dropoff_point.svg");
}
.mi-basic-location {
  background-image: url("#{$base}/booking/basic_location.svg");
}
.mi-basic-clock {
  mask-image: url("#{$base}/booking/basic_clock.svg");
}

.mi-basic-clock-fill {
  mask-image: url("#{$base}/booking/basic_clock_fill.svg");
}

.mi-locations {
  background-size: 16px 16px;
  background-repeat: no-repeat;
}

.mi-stop-point {
  background-image: url("#{$base}/booking/stops_circle.svg");
}

.mi-additional-vehicle-icon {
  mask-image: url("#{$base}/booking/additional_vehicle_icon.svg");
}

.mi-vehicle-transfer-icon {
  mask-image: url("#{$base}/booking/vehicle_transfer_icon.svg");
}

.mi-airport-icon {
  mask-image: url("#{$base}/booking/airport_icon.svg");
}

.mi-baby-seat-icon {
  mask-image: url("#{$base}/booking/baby_seat_icon.svg");
}

.mi-booking-total-pass {
  mask-image: url("#{$base}/booking/booking_total_pass.svg");
}

.mi-car-journey {
  mask-image: url("#{$base}/booking/car_journey.svg");
}

.mi-icon-sort {
  mask-image: url("#{$base}/booking/icon-sort.svg");
}

.mi-instructions-notes {
  mask-image: url("#{$base}/booking/instructions_notes.svg");
}

.mi-journey-preference {
  mask-image: url("#{$base}/booking/journey_prefrence.svg");
}

.mi-luggage-vehicle-icon {
  mask-image: url("#{$base}/booking/luggage_vehicle_icon.svg");
}

.mi-luggages-icon {
  mask-image: url("#{$base}/booking/luggages_icon.svg");
}

.mi-pet-accommodation {
  mask-image: url("#{$base}/booking/pet_accomodation.svg");
}

.mi-pickup-icon {
  mask-image: url("#{$base}/booking/pickup_icon.svg");
}

.mi-select-map-icon {
  mask-image: url("#{$base}/booking/select_map_icon.svg");
  // width: 24px;
  // height: 24px;
  background: #262c2d;
}

.mi-shuttle {
  mask-image: url("#{$base}/booking/shuttle_icon.svg");
}

.mi-vehicle-transfer {
  mask-image: url("#{$base}/booking/vehicle_transfer.svg");
}

.mi-wheel-chair-icon {
  mask-image: url("#{$base}/booking/wheel_chair_icon.svg");
}

.mi-edit-plus-fill-circle {
  mask-image: url("#{$base}/booking/edit_plus_fill_circle.svg");
}

.mi-passenger_type_photo {
  mask-image: url("#{$base}/booking/passenger_type_photo.svg");
}

.mi-unique-id{
  mask-image: url("#{$base}/booking/unique_id.svg");
}

.mi-trip-notification{
  mask-image: url("#{$base}/booking/trip_notification.svg");
}

.mi-mobile-number{
  mask-image: url("#{$base}/booking/mobile_number.svg");
}

.mi-passenger-vehicle{
  mask-image: url("#{$base}/booking/passenger_vehicle_manual.svg");
}

.mi-auto-dispatch{
  mask-image: url("#{$base}/booking/booking_auto_dispatch.svg");
}

.mi-manual-dispatch{
  mask-image: url("#{$base}/booking/booking_manual_dispatch.svg");
}

.mi-route-chain {
  background-image: url("#{$base}/booking/route.svg");
}

.mi-radio-filled{
  background-image: url("#{$base}/booking/basic_radio_filled.svg");
}

.mi-plus-circle {
  background-image: url("#{$base}/booking/plus-circle.svg");
}

.mi-live_dashboard {
  mask-image: url("#{$base}/nav/live_dashboard_menu.svg");
}

.mi-heat_map {
  mask-image: url("#{$base}/nav/heat_map.svg");
}

.mi-activity_feed {
  mask-image: url("#{$base}/nav/activity_feed.svg");
}

.mi-manage_columns {
  mask-image: url("#{$base}/nav/manage_columns.svg");
}

.mi-full_screen {
  mask-image: url("#{$base}/nav/full_screen.svg");
}

.mi-small_screen {
  mask-image: url("#{$base}/nav/small_screen.svg");
}
.mi-basic_sort {
  mask-image: url("#{$base}/nav/basic_sort.svg");
}

.mi-fleet-nxt{
  mask-image: url("#{$base}/nav/fleet-nxt-logo.svg");
}
.mi-fleet-nxt-logo{
  mask-image: url("#{$base}/nav/fleet_nxt_logo_blue.svg");
}
.mi-fleet-nxt-closed-logo{
  mask-image: url("#{$base}/nav/fleext_nxt_logo_closed_view.svg");
}

.mi-stepper_radio {
  mask-image: url("#{$base}/basic/radio_filled_stepper.svg");
}

.mi-stepper_color {
  background-color: #1e90ff;
}

.mi-request_for_transport {
  mask-image: url("#{$base}/basic/request_for_transport.svg");
}

.mi-on_demand_transport {
  mask-image: url("#{$base}/basic/on_demand_transport.svg");
}

.mi-vehicle_transfer {
  mask-image: url("#{$base}/basic/vehicle_transfer.svg");
}

.mi-baby_seat, .mi-infant_carrier {
  mask-image: url("#{$base}/basic/baby_seat.svg");
}

.mi-wheel_chair {
  mask-image: url("#{$base}/basic/wheel_chair.svg");
}

.mi-locate_me_target {
  mask-image: url("#{$base}/map/locate_me_target.svg");
}

.mi-day_mode {
  mask-image: url("#{$base}/map/day_mode.svg");
}

.mi-night_mode {
  mask-image: url("#{$base}/map/night_mode.svg");
}

.mi-redirect_feed {
  mask-image: url("#{$base}/basic/redirect_feed.svg");
}

.mi-activity_feed_route_icon {
  mask-image: url("#{$base}/booking/complete_icon_without_stop.svg");
}

.arrow_left {
  mask-image: url("#{$base}/basic/arrow_left.svg");
}

.arrow_right {
  mask-image: url("#{$base}/basic/arrow_right.svg");
}

.mi-endofshift {
  mask-image: url("#{$base}/basic/clock.svg");
}

.mi-scheduledbreak {
  mask-image: url("#{$base}/calendar/calendar_event.svg");
}

.mi-mealbreak {
  mask-image: url("#{$base}/basic/meal.svg");
}

.mi-waitingforloadingunloading {
  mask-image: url("#{$base}/basic/log_out.svg");
}

.mi-maintenanceorrepairs {
  mask-image: url("#{$base}/settings_overlay.svg");
}

.mi-non-drivingduties {
  mask-image: url("#{$base}/nav/Document.svg");
}

.mi-manage-groups{
  mask-image: url("#{$base}/nav/Manage_groups.svg");
}

.mi-manage-shift{
  mask-image: url("#{$base}/nav/manage_shift.svg");
}

.mi-shift-calendar{
  mask-image: url("#{$base}/nav/shift_calendar.svg");
}

.mi-shift-template{
  mask-image: url("#{$base}/nav/shift_template.svg");
}

.mi-manage-roles{
  mask-image: url("#{$base}/nav/manage_roles.svg");
}

.mi-manage-users{
  mask-image: url("#{$base}/nav/manage_users.svg");
}

.mi-booking-history{
  mask-image: url("#{$base}/nav/booking_history.svg");
}

.mi-current-booking{
  mask-image: url("#{$base}/nav/current_booking.svg");
}

.mi-all-drivers{
  mask-image: url("#{$base}/nav/all_drivers.svg");
}

.mi-attendance-management{
  mask-image: url("#{$base}/nav/attendance_management.svg");
}

.mi-leave-management{
  mask-image: url("#{$base}/nav/leave_management.svg");
}

.mi-about-us{
  mask-image: url("#{$base}/nav/about_us.svg");
}

.mi-faq{
  mask-image: url("#{$base}/nav/faqs.svg");
}

.mi-terms-conditions{
  mask-image: url("#{$base}/nav/terms_conditions.svg");
}

.mi-privacy-policy{
  mask-image: url("#{$base}/nav/privacy_policy.svg");
}

.mi-contact-support{
  mask-image: url("#{$base}/nav/contact_support.svg");
}

.mi-personaltime {
  mask-image: url("#{$base}/basic/happy.svg");
}

.mi-history {
  mask-image: url("#{$base}/basic/history.svg");
}

.mi-queue_management {
  mask-image: url("#{$base}/basic/queue_management.svg");
}

.mi-logoff {
  mask-image: url("#{$base}/basic/clock.svg");
}

.mi-basic_clock {
  mask-image: url("#{$base}/basic/basic_clock.svg");
}

.mi-breaks {
  mask-image: url("#{$base}/basic/breaks.svg");
}

.mi-history {
  mask-image: url("#{$base}/basic/history.svg");
}

.mi-organization {
  mask-image: url("#{$base}/basic/organization.svg");
}

.mi-minus {
  mask-image: url("#{$base}/basic/minus.svg");
}

.mi-toggle-on {
  mask-image: url("#{$base}/basic/toggle_on.svg");
}

.mi-toggle-off {
  mask-image: url("#{$base}/basic/toggle_off.svg");
}

.mi-default-user {
  mask-image: url("#{$base}/basic/default_user.svg");
}

.mi-calendar_settings {
  mask-image: url("#{$base}/basic/dashboard_calendar_settings.svg");
}

.event-menu, .mi-onboarding{
  mask-image: url("#{$base}/basic/new_event.svg");
}

.calendar_date_time {
  mask-image: url("#{$base}/basic/calendar_date_time.svg");
}

.passenger_user {
  mask-image: url("#{$base}/basic/passenger_user.svg");
}

.female_gender {
  mask-image: url("#{$base}/basic/female_gender.svg");
}

.male_gender {
  mask-image: url("#{$base}/basic/male_gender.svg");
}

.others_gender {
  mask-image: url("#{$base}/booking/gender.svg");
}

.search-small {
  mask-image: url("#{$base}/basic/search_small.svg");
}

.bookmark {
  mask-image: url("#{$base}/basic/bookmark.svg");
}

.home-dark {
  mask-image: url("#{$base}/basic/home_dark.svg");
}

.hotel-dark {
  mask-image: url("#{$base}/basic/hotel_dark.svg");
}

.driver-icon {
  mask-image: url("#{$base}/basic/driver_icon.svg");
}

.content-management {
  mask-image: url("#{$base}/basic/content-management.svg");
}
